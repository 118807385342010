<script lang="ts">
	import { Tooltip } from 'bits-ui';
	import { page } from '$app/stores';
	import { flyAndScale } from '$lib/app/utils';
	import { tap, type TapPointerEventDetail } from 'svelte-gestures';
	import clickOutside from '$lib/home/click-outside';

	export let href: string;
	export let icon;
	export let text: string;

	let isActive = $page.url.pathname.startsWith(href);
	let open = false;

	function onTap(e: CustomEvent<TapPointerEventDetail>) {
		e.preventDefault();
		open = !open;
	}
</script>

{#if $$slots.default}
	<Tooltip.Root openDelay={0} bind:open closeOnPointerDown={false}>
		<Tooltip.Trigger class="max-md:w-full">
			<!-- TODO: maybe dry with below -->
			<a href="/resources"
				 class="font-medium text-lg flex items-center justify-center gap-x-2
	 				hover:bg-slate-100 py-2 md:py-1 px-3 md:px-2 lg:px-3 rounded-md transition-colors duration-300"
				 class:bg-slate-100={isActive}
				 use:tap on:tap={onTap}
				 on:click={(e) => e.preventDefault()}
				 use:clickOutside on:clickOutside={() => open = false}
			>
				<svelte:component this={icon} class="size-6" />
				{text}
			</a>
		</Tooltip.Trigger>
		<Tooltip.Content side="bottom"
										 sideOffset={12}
										 transition={flyAndScale}
										 data-sveltekit-reload=""
										 class="z-30 max-md:w-[calc(100%-60px)]">
			<div class="bg-white">
				<Tooltip.Arrow class="rounded-[2px] border-t border-l" />
			</div>

			<div class="bg-white rounded-md shadow-popover border">
				<slot />
			</div>
		</Tooltip.Content>
	</Tooltip.Root>
{:else}
	<!-- TODO: dry -->
	<a href={href}
		 class="max-md:w-full font-medium text-lg flex items-center justify-center gap-x-2
	 				hover:bg-slate-100 py-2 md:py-1 px-3 md:px-2 lg:px-3 rounded-md transition-colors duration-300"
		 class:bg-slate-100={isActive}>
		<svelte:component this={icon} class="size-6" />
		{text}
	</a>
{/if}
