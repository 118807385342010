<script lang="ts">
	export let href: string;
	export let text: string;
</script>

<li>
	<a href={href} class="hover:underline">
		{text}
	</a>
</li>
