<script lang="ts">
	import { page } from '$app/stores';

	const fallback = {
		title: 'DMARCwise',
		description: 'Easy DMARC monitoring to protect your domain from abuse. ' +
			'A powerful dashboard, email digests and more to easily monitor and fix email delivery issues.',
		ogType: 'website',
		ogImage: 'https://dmarcwise.io/og-image.jpg'
	};

	$: seo = $page.data.seo ?? {};

	$: title = seo.title || fallback.title;
	$: description = seo.description || fallback.description;
	$: ogTitle = seo.ogTitle || seo.title || fallback.title;
	$: ogType = seo.ogType || fallback.ogType;
	$: ogImage = seo.ogImage || fallback.ogImage;
</script>

<title>{title}</title>
<meta name="description" content={description} />
<meta property="og:site_name" content="DMARCwise" />
<meta property="og:title" content={ogTitle} />
<meta property="og:type" content={ogType} />
<meta property="og:description" content={description} />
<meta property="og:image" content={ogImage} />
<meta name="twitter:card" content="summary_large_image" />
<meta name="twitter:site" content="@DMARCwise" />
<meta name="twitter:image" content={ogImage} />

{#if seo.ogAuthor}
	<meta property="og:author" content={seo.ogAuthor} />
{/if}

{#if seo.ogModified}
	<meta property="og:updated_time" content={seo.ogModified.toISOString()} />
{/if}

{#if seo.noIndex}
	<meta name="robots" content="noindex" />
{/if}

<link rel="canonical" href="https://dmarcwise.io{$page.url.pathname}" />
