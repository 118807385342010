<script lang="ts">
	import '../../home.css';
	import '@fontsource-variable/inter';
	import '@fontsource-variable/jetbrains-mono';
	import Header from '$lib/home/components/layout/header.svelte';
	import Footer from '$lib/home/components/layout/footer.svelte';
	import MetaTags from '$lib/home/components/meta-tags.svelte';
	import setupOpenPanel from '$lib/openpanel';
	import { browser } from '$app/environment';

	if (browser) {
		setupOpenPanel();
	}
</script>

<svelte:head>
	<MetaTags />
</svelte:head>

<div style="display: contents" data-sveltekit-reload="">
	<Header />
	<slot />
	<Footer />

	<script>
		if (window.location.host !== 'dmarcwise.io') window.goatcounter = { no_onload: true };
	</script>

	<script defer src="https://gc.zgo.at/count.js" data-goatcounter="https://dmarcwise.goatcounter.com/count"></script>
</div>
